const action = {
    sign_in: 'تسجيل الدخول',
    continue: 'متابعة',
    continue_as: 'المتابعة كـ {{name}}',
    create_account: 'إنشاء حساب',
    create_account_without_linking: 'إنشاء حساب جديد بدلاً من الربط',
    create: 'إنشاء',
    enter_passcode: 'أدخل رمز التحقق',
    confirm: 'تأكيد',
    cancel: 'إلغاء',
    save_password: 'حفظ كلمة المرور',
    bind: 'ربط مع {{address}}',
    bind_and_continue: 'ربط ومتابعة',
    back: 'العودة',
    nav_back: 'رجوع',
    nav_skip: 'تخطي',
    agree: 'موافق',
    got_it: 'فهمت',
    sign_in_with: 'تسجيل الدخول باستخدام {{name}}',
    forgot_password: 'هل نسيت كلمة المرور؟',
    switch_to: 'التبديل إلى {{method}}',
    sign_in_via_passcode: 'تسجيل الدخول باستخدام رمز التحقق',
    sign_in_via_password: 'تسجيل الدخول باستخدام كلمة المرور',
    change: 'تغيير {{method}}',
    link_another_email: 'ربط بريد إلكتروني آخر',
    link_another_phone: 'ربط هاتف آخر',
    link_another_email_or_phone: 'ربط بريد إلكتروني أو هاتف آخر',
    show_password: 'عرض كلمة المرور',
    copy: 'نسخ',
    verify_via_passkey: 'التحقق عبر مفتاح التحقق',
    download: 'تحميل',
    single_sign_on: 'تسجيل الدخول الموحد',
    authorize: 'التفويض',
    use_another_account: 'استخدام حساب آخر',
    back_to_current_account: 'الرجوع إلى الحساب الحالي',
};
export default Object.freeze(action);
