const cloud = {
    general: {
        onboarding: 'عملية التسجيل',
    },
    create_tenant: {
        page_title: 'إنشاء مستأجر',
        title: 'أنشئ أول مستأجر لك',
        description: 'المستأجر هو بيئة معزولة حيث يمكنك إدارة هويات المستخدمين والتطبيقات وجميع الموارد الأخرى في Logto.',
        invite_collaborators: 'ادعو مشاركيك عبر البريد الإلكتروني',
    },
    social_callback: {
        title: 'لقد قمت بتسجيل الدخول بنجاح',
        description: 'لقد قمت بتسجيل الدخول بنجاح باستخدام حسابك الاجتماعي. لضمان التكامل السلس والوصول إلى جميع ميزات Logto، نوصي بمتابعة تكوين موصلك الاجتماعي الخاص بك.',
        notice: 'يرجى تجنب استخدام الموصل التجريبي لأغراض الإنتاج. بمجرد الانتهاء من الاختبار، نرجو حذف الموصل التجريبي وإعداد موصلك الخاص باستخدام بيانات الاعتماد الخاصة بك.',
    },
    tenant: {
        create_tenant: 'إنشاء مستأجر',
    },
};
export default Object.freeze(cloud);
